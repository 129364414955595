<template>
  <component :is="noteData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="noteData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching note data
      </h4>
      <div class="alert-body">
        No notes found with this note id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-notes-list'}"
        >
          Notes List
        </b-link>
        for other notes.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Title"
              rules="required"
          >
            <b-form-group
                label="Title En"
                label-for="mc-title-en"
            >
              <b-form-input
                  v-model="noteData.title_en"
                  id="mc-title-en"
                  placeholder="Title En"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="noteData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group
              label="Choose PDF Type"
              :label-for="'pdf_type'"
          >
            <validation-provider
                #default="{ errors }"
                name="PDF Type"
                rules="required"
            >
              <b-form-radio
                  v-model="noteData.pdf_type"
                  class="custom-control-primary"
                  :name="'pdf_type'"
                  :state="errors.length > 0 ? false:null"
                  value="1"
              >
                Upload File
              </b-form-radio>
              <b-form-radio
                  v-model="noteData.pdf_type"
                  class="custom-control-primary"
                  :name="'pdf_type'"
                  :state="errors.length > 0 ? false:null"
                  value="2"
              >
                Insert URL
              </b-form-radio>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
          <b-col md="5" v-if="noteData.pdf_type == 1">
            <b-form-group
                label="Select Note PDF"
                label-for="mc-is-active"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Note PDF"
                  rules="required"
              >
                <b-form-file
                    v-model="noteData.file"
                    :id="'wildcard-video'"
                    accept="application/pdf"
                    @change="uploadPdf($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="5" v-if="noteData.pdf_type == 2">
            <b-form-group
                label="Enter PDF URL"
                label-for="mc-is-active"
            >
              <validation-provider
                  #default="{ errors }"
                  name="PDF URL"
                  rules="required"
              >
                <b-form-input
                    v-model="noteData.path"
                    type="url"
                    :id="'wildcard'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
                label="Current PDF File"
                label-for="current-pdf"
            >
              <a
                  class="btn btn-primary"
                  :href="noteData.url"
                  target="_blank"
              >
                View PDF
              </a>
            </b-form-group>
          </b-col>
        </b-row>
      <b-row>

        <b-col md="6">
          <!-- Course -->
          <validation-provider
              #default="validationContext"
              name="Choose Course"
              rules="required"
          >
            <b-form-group
                label="Choose Course"
                label-for="course"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="noteData.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="courseOptions"
                  @input="val => updateCourseID(val)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="categoriesOptions.length > 0"
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="noteData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateCategoryId(val)"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="noteData.subs.length > 0"
              #default="validationContext"
              name="Choose Sub Category"
              rules="required"
          >
            <b-form-group
                label="Choose Sub Category"
                label-for="sub-category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="noteData.sub_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="noteData.subs"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateSubCategoryId(val)"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Free Content"
              label-for="is_free_content"
          >
            <b-form-checkbox
                id="is_free_content"
                v-model="noteData.is_free_content"
                :checked=noteData.is_free_content
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="noteData.is_active"
                :checked=noteData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>


      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import noteStoreModule from '../noteStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert, BButton, BCard, BCol, BForm, BFormInvalidFeedback,BFormCheckbox, BFormGroup, BFormFile, BFormInput, BLink, BRow,BFormTextarea,BFormRadio} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BCard,
    BFormRadio,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
          let course_id = this.noteData.course_id
          this.$http.get('/categories?course_id='+course_id)
              .then(response => {
                let that = this
                this.lists = response.data.data.map(function(item){
                  that.categoriesOptions.push({
                    label : item.label,
                    value: item.value+""
                  })
                })
              })
        })
  },
  methods:{
    updateSubCategoryId(val){
      this.noteData.sub_category_id = val
    },
    updateCategoryId(category_id) {
      this.noteData.category_id = category_id
      this.noteData.subs.splice(0)
      this.$http.get('/sub-categories?'+'course_id='+this.noteData.course_id+'&category_id='+category_id)
          .then(response => {
            let that = this
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.noteData.subs.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    updateCourseID(course_id){
      this.noteData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.noteData.subs.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.updateCategoryId(this.noteData.category_id)
    },
    setImage(imageId) {
      this.noteData.video_thumb = imageId
    },
    changeValue(){
      let val = this.noteData.video_type
      this.noteData.video_type = val
    },
    uploadPdf(event){
      let data = new FormData();
      data.append('name', 'my-pdf');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.noteData.path = response.data.path
      })
    },
  },
  data(){
    return{
      required,
      url,
      courseOptions: [],
      rerender_component: 0,
      show: false,
      lists: [],
      categoriesOptions: [],
      video_type: null,
      video_type_options: [
        {
          label : "Upload video",
          value: "upload"
        },
        {
          label : "Insert a URL",
          value: "url"
        }
      ],
    }
  },
  setup() {
    const blankNoteData = {
      title_en: '',
      title_ar: '',
      is_free_content: null,
      course_id: '',
      url: null,
      category_id: '',
      path: null,
      file: null,
      pdf_type: 2,
      sub_category_id: '',
      subs: []
    }
    const lectureImageId = ref(null);
    const toast = useToast()

    const noteData = ref(JSON.parse(JSON.stringify(blankNoteData)))
    const resetNoteData = () => {
      noteData.value = JSON.parse(JSON.stringify(blankNoteData))
    }

    const EVENT_APP_STORE_MODULE_NAME = 'notes'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, noteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('notes/fetchNote', {id: router.currentRoute.params.id})
        .then(response => {
          noteData.value = response.data.data
          noteData.value.pdf_type = 2
          noteData.value.path = response.data.data.url
        })
        .catch(error => {
          if (error.response.status === 404) {
            noteData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: noteData.value.title_en,
        title_ar: noteData.value.title_ar,
        is_active: noteData.value.is_active,
        is_free_content: noteData.value.is_free_content,
        url: noteData.value.path,
        course_id: noteData.value.course_id,
        category_id: noteData.value.category_id,
        sub_category_id: noteData.value.sub_category_id,
      }
      store.dispatch('notes/updateNote', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-notes-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Note Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetNoteData)
    return {
      onUpdate,
      noteData,
      lectureImageId,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
